import { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthContextProvider } from "./services/AuthContext";

import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { SnackbarProvider } from "notistack";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { TopBar } from "./common/topbar";
import { Copyright } from "./common/footer";

import { SignIn } from "./views/auth/signin";
import { SignUp } from "./views/auth/signup";
import { ResetPassword } from "./views/auth/reset";

import { Home } from "./views/home";

import "./App.css";
import ProtectedRoute from "./common/protectedroute";

function App() {
  useEffect(() => {
    document.title = "WESTCO";
  }, []);

  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#c40f33",
      },
      secondary: {
        main: "#066d3b",
      },
    },
    typography: {
      fontFamily: [
        "Teko",
        "-apple-system",
        "BlinkMacSystemFont",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      fontSize: 16,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthContextProvider>
          <Router>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "97vh",
              }}
            >
              <TopBar />
              <SnackbarProvider
                variant="info"
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                sx={{ fontSize: 32 }}
              />
              <Container component="main" sx={{ mt: 2, mb: 2 }} maxWidth="md">
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Home />}
                  />
                  <Route exact path="/signin" element={<SignIn />} />
                  <Route exact path="/signup" element={<SignUp />} />
                  <Route exact path="/reset" element={<ResetPassword />} />
                  
                </Routes>
              </Container>
              <Box
                component="footer"
                sx={{
                  py: 2,
                  px: 2,
                  mt: "auto",
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[200]
                      : theme.palette.grey[800],
                }}
              >
                <Container maxWidth="sm">
                  <Copyright />
                </Container>
              </Box>
            </Box>
          </Router>
        </AuthContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
