import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export const TopBar = () => {

  return (
    <AppBar position="static" className="westco-red">
      <Container maxWidth="xl">
        <Toolbar disableGutters> 
          <Typography
            variant="h5"
            noWrap
            sx={{
              mr: 2,
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            WESTCO
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

